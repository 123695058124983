import { IS_DEV } from "./env";

class SlackService {
    async sendSlackNotification(message: string) {
        const webhookUrl =
            'https://hooks.slack.com/services/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';
        const slackData = { text: `[${IS_DEV ? "DEV" : "PROD"}]: ${message}` };
        try {
            await fetch(webhookUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(slackData),
            });
        } catch (error) {
            console.error('Error sending Slack notification:', error);
        }
    }
}

export default new SlackService();